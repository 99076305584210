import Banner from "../shared/Banner";
import banner from "../assets/banner.png";

const Home = () => {
  return (
    <div className="md:px-12 p-4 max-w-screen-2xl mx-auto mt-32" id="home">
      <Banner
        banner={banner}
        heading=" Develop your skills without diligence"
        subHeading="For the most part, people have come to accept websites as a
              necessity, but there are a few holdouts remaining who don't see
              the need"
        btn1="Get Started"
        btn2="Discount"
      />
    </div>
  );
};

export default Home;
