import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const Banner = ({ banner, heading, subHeading, btn1, btn2 }) => {
  return (
    <div>
      <div className="gradientBg rounded-xl rounded-br-[5rem] md:p-9 px-4 py-9">
        <div className="flex flex-col md:flex-row-reverse justify-between items-center gap-10">
          {/* Banner Image */}
          <motion.div
            variants={fadeIn("down", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
          >
            <img className="lg:h-[24rem]" src={banner} alt="banner" />
          </motion.div>
          {/* Banner Content */}
          <motion.div
            className="md:w-[60%]"
            variants={fadeIn("up", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
          >
            <h2 className="md:text-7xl text-4xl font-bold text-white mb-6 leading-relaxed">
              {heading}
            </h2>
            <span className="text-[#EBEBEB] text-2xl mb-8">{subHeading}</span>
            <div className="space-x-5 space-y-4 mt-[2rem]">
              <button className="btnPrimary">{btn1}</button>
              {btn2 && <button className="btnPrimary">{btn2}</button>}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
