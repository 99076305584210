import { useState } from "react";
import Logo from "../assets/logo.png";
import { GrLanguage } from "react-icons/gr";
import { FaXmark, FaBars } from "react-icons/fa6";
import { Link } from "react-scroll";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navItems = [
    { link: "Overview", path: "home" },
    { link: "Feature", path: "feature" },
    { link: "About", path: "about" },
    { link: "Pricing", path: "pricing" },
  ];

  return (
    <>
      <nav className="bg-white md:px-14 border-b p-4 max-w-screen-2xl mx-auto text-primary fixed top-0 right-0 left-0">
        <div className="text-lg container mx-auto flex items-center justify-between">
          <div className="flex space-x-14 items-center">
            <a
              className="text-2xl font-semibold flex items-center space-x-3 text-primary"
              href="/"
            >
              <img className="w-[5rem]" src={Logo} alt="logo" />
              <span>Mr.Teddy</span>
            </a>
            <ul className="hidden md:flex space-x-12">
              {navItems.map(({ link, path }, index) => {
                return (
                  <Link
                    spy={true}
                    smooth={true}
                    offset={-100}
                    activeClass="active"
                    className="hover:text-gray-300 font-semibold cursor-pointer"
                    key={index}
                    to={path}
                  >
                    {link}
                  </Link>
                );
              })}
            </ul>
          </div>
          <div className="space-x-12 hidden md:flex items-center">
            <a
              className="hidden lg:flex items-center hover:text-secondary"
              href="/"
            >
              <GrLanguage className="mr-2" /> <span>Language</span>
            </a>
            <button className="bg-secondary py-2 px-4 transition-all duration-300 rounded-md hover:text-white">
              Sign up
            </button>
          </div>
          <div className="md:hidden">
            <button
              onClick={menuToggle}
              className="text-white focus:outline-none focus:text-gray-300"
            >
              {isMenuOpen ? (
                <FaXmark className="text-3xl text-primary" />
              ) : (
                <FaBars className="text-3xl text-primary" />
              )}
            </button>
          </div>
        </div>
      </nav>
      <div
        className={`space-y-4 px-4 pt-32 pb-5 bg-secondary ${
          isMenuOpen ? "block fixed top-0 right-0 left-0" : "hidden"
        }`}
      >
        {navItems.map(({ link, path }, index) => {
          return (
            <Link
              onClick={menuToggle}
              spy={true}
              smooth={true}
              offset={-80}
              activeClass="active"
              className="hover:text-gray-300 text-xl font-semibold block cursor-pointer text-white"
              key={index}
              to={path}
            >
              {link}
            </Link>
          );
        })}
      </div>
    </>
  );
};

export default Navbar;
