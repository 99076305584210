import about1 from "../assets/about1.png";
import about2 from "../assets/about2.png";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const About = () => {
  return (
    <div
      className="md:px-14 p-4 max-w-screen-2xl mx-auto space-y-10"
      id="about"
    >
      <div className="flex flex-col md:flex-row justify-between items-center gap-8">
        <motion.div
          variants={fadeIn("right", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="md:w-[50%]"
        >
          <img src={about1} alt="about1" />
        </motion.div>
        {/* about content */}
        <motion.div
          variants={fadeIn("left", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="md:w-[50%]"
        >
          <h2 className="md:text-5xl text-3xl font-bold text-primary mb-5 leading-normal">
            We have been improving our product{" "}
            <span className="text-secondary">for many years.</span>
          </h2>
          <p className="text-tartiary text-lg mb-7">
            Maintainin a high level of productivity is not intuitive or easy but
            you dont have to resign yourself to feeling discouraged and depleted
            at the end of every workday.
          </p>
          <button className="btnPrimary">Get Started</button>
        </motion.div>
      </div>
      {/* 2nd part */}
      <div className="flex flex-col md:flex-row-reverse justify-between items-center gap-8">
        <motion.div
          variants={fadeIn("up", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="md:w-[50%]"
        >
          <img src={about2} alt="about1" />
        </motion.div>
        {/* about content */}
        <motion.div
          variants={fadeIn("right", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="md:w-[50%]"
        >
          <h2 className="md:text-5xl text-3xl font-bold text-primary mb-5 leading-normal">
            You can Practice at any
            <div className="text-secondary">time convinent for you.</div>
          </h2>
          <p className="text-tartiary text-lg mb-7">
            Maintainin a high level of productivity is not intuitive or easy but
            you dont have to resign yourself to feeling discouraged and depleted
            at the end of every workday.
          </p>
          <button className="btnPrimary">Get Started</button>
        </motion.div>
      </div>
    </div>
  );
};

export default About;
