import React from "react";
import about1 from "../assets/about1.png";

const BackgroundImage = () => {
  const welcomeImgStyle = {
    height: "20rem",
    width: "20rem",
    position: "relative",
    backgroundImage: `url(../assets/about1.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  return (
    <div className="mt-[10rem]">
      <div
        style={{
          backgroundImage: `url(../assets/about1.png))`,
          backgroundSize: "cover",
          height: "40rem",
          width: "40rem",
          position: "relative", // Optional: Set the background size to cover the entire container
        }}
      >
        <div className="text-black absolute top-[10rem] right-0">
          Hello this is the text
        </div>
      </div>
    </div>
  );
};

export default BackgroundImage;
