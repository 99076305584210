import Logo from "../assets/logo.png";
import facebook from "../assets/facebook.png";
import instagram from "../assets/instagram.png";
import twitter from "../assets/twitter.png";
import linkedin from "../assets/linkedIn.png";

const Footer = () => {
  return (
    <div className="bg-[#010851] md:px-14 p-4 max-w-screen-2xl mx-auto text-white">
      <div className="my-12 flex flex-col md:flex-row gap-10">
        <div className="md:w-[50%] space-y-8">
          <a
            className="text-2xl font-semibold flex items-center space-x-3 text-primary"
            href="/"
          >
            <img className="w-[5rem]" src={Logo} alt="logo" />
            <span className="text-white">Mr.Teddy</span>
          </a>
          <p className="md:w-[50%]">
            A simple paragraph is comprised of three major components. The first
            sentence, which is often a declarative sentence.
          </p>
          <div>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Your email"
              className="bg-[#9a7af159] py-2 px-4 rounded-md focus:outline-none"
            />
            <input
              type="submit"
              value="Subscribe"
              className="px-4 py-2 bg-secondary rounded-md -ml-2 cursor-pointer hover:bg-white hover:text-primary duration-300 transition-all"
            />
          </div>
        </div>
        {/* footer navigations */}
        <div className="md:w-[50%] flex flex-cols md:flex-row flex-wrap justify-between gap-8 items-start">
          <div className="space-y-6 mt-5">
            <h4 className="text-xl">Platform</h4>
            <ul className="space-y-3">
              <a className="block hover:text-gray-300" href="/">
                Overview
              </a>
              <a className="block hover:text-gray-300" href="/">
                Features
              </a>
              <a className="block hover:text-gray-300" href="/">
                About
              </a>
              <a className="block hover:text-gray-300" href="/">
                Pricing
              </a>
            </ul>
          </div>
          <div className="space-y-6 mt-5">
            <h4 className="text-xl">Help</h4>
            <ul className="space-y-3">
              <a className="block hover:text-gray-300" href="/">
                How does it works?
              </a>
              <a className="block hover:text-gray-300" href="/">
                Where to ask question?
              </a>
              <a className="block hover:text-gray-300" href="/">
                How to play?
              </a>
              <a className="block hover:text-gray-300" href="/">
                What is needed for this?
              </a>
            </ul>
          </div>
          <div className="space-y-6 mt-5">
            <h4 className="text-xl">Contacts</h4>
            <ul className="space-y-3">
              <p className="hover:text-gray-300" href="/">
                09757814509
              </p>
              <p className="hover:text-gray-300" href="/">
                pyaephyohan2200@gmail.com
              </p>
              <p className="hover:text-gray-300" href="/">
                Bayint Naung, Mayangone, Yangon.
              </p>
              <p className="hover:text-gray-300" href="/">
                Super Pyae Phyo Han
              </p>
            </ul>
          </div>
        </div>
      </div>
      <hr />
      <div className="flex flex-col sm:flex-row gap-8 sm:items-center justify-between my-8">
        <p>@ Mr.Teddy. All rights reserved.</p>
        <div className="flex items-center space-x-5">
          <img
            src={facebook}
            alt="facebook"
            className="w-8 cursor-pointer hover:-translate-y-4 transition-all duration-300"
          />
          <img
            src={instagram}
            alt="instagram"
            className="w-8 cursor-pointer hover:-translate-y-4 transition-all duration-300"
          />
          <img
            src={twitter}
            alt="twitter"
            className="w-8 cursor-pointer hover:-translate-y-4 transition-all duration-300"
          />
          <img
            src={linkedin}
            alt="linkedin"
            className="w-8 cursor-pointer hover:-translate-y-4 transition-all duration-300"
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
