import { useState } from "react";
import green from "../assets/green.png";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const Pricing = () => {
  const [isYearly, setIsYearly] = useState(true);

  const packages = [
    {
      name: "Start",
      monthlyPrice: 19,
      yearlyPrice: 199,
      description:
        "A common form of Lorem ipsum reads: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      green,
    },
    {
      name: "Advance",
      monthlyPrice: 39,
      yearlyPrice: 399,
      description:
        "A common form of Lorem ipsum reads: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      green,
    },
    {
      name: "Premium",
      monthlyPrice: 59,
      yearlyPrice: 599,
      description:
        "A common form of Lorem ipsum reads: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      green,
    },
  ];

  return (
    <div className="md:px-14 p-4 max-w-screen-2xl mx-auto py-10" id="pricing">
      <div className="text-center">
        <h2 className="md:text-5xl text-3xl text-primary font-extrabold mb-2">
          Here are all our plans
        </h2>
        <p className="text-tartiary md:w-[33.33%] mx-auto px-4">
          Plants have so many awesome benefits for our life and wellbeing. Money
          plants are Chinese plants that are loved by people all over the world.
        </p>
        {/* toggle pricing */}
        <div className="mt-16">
          <label
            htmlFor="toggle"
            className="inline-flex items-center cursor-pointer"
          >
            <span className="mr-8 text-2xl font-semibold">Monthly</span>
            <div
              className={`w-14 h-6 rounded-full transition-all duration-200 ease-in-out ${
                isYearly ? "bg-secondary" : "bg-gray-300"
              }`}
            >
              <div
                className={`w-6 h-6 rounded-full ${
                  isYearly ? "bg-primary ml-8" : "bg-gray-500"
                }`}
              ></div>
            </div>
            <span className="ml-8 text-2xl font-semibold">Yearly</span>
          </label>
          <input
            type="checkbox"
            id="toggle"
            className="hidden"
            checked={isYearly}
            onChange={() => {
              setIsYearly(!isYearly);
            }}
          />
        </div>
      </div>
      {/* pricing cards */}
      <motion.div
        variants={fadeIn("up", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.5 }}
        className="grid sm:grid-cols-2 lg:grid-cols-3 gap-10 mt-20 md:w-[90%] mx-auto]"
      >
        {packages.map((pkg, index) => (
          <div
            className="border py-10 md:px-6 px-4 rounded-lg shadow-3xl"
            key={index}
          >
            <h3 className="text-3xl font-bold text-center text-primary">
              {pkg.name}
            </h3>
            <p className="text-tartiary text-center my-5">{pkg.description}</p>
            <p className="my-[2rem] text-center text-secondary text-4xl font-bold">
              {isYearly ? `$${pkg.yearlyPrice}` : `$${pkg.monthlyPrice}`}{" "}
              <span className="text-base text-tartiary font-medium">
                /{isYearly ? "yearly" : "monthly"}
              </span>
            </p>
            <ul className="space-y-2 px-4">
              <li className="flex gap-3 items-center">
                <img
                  className="w-4 h-4"
                  src={pkg.green}
                  alt="Green Rectangle"
                />
                Videos of Lessons
              </li>
              <li className="flex gap-3 items-center">
                <img
                  className="w-4 h-4"
                  src={pkg.green}
                  alt="Green Rectangle"
                />
                Homework check
              </li>
              <li className="flex gap-3 items-center">
                <img
                  className="w-4 h-4"
                  src={pkg.green}
                  alt="Green Rectangle"
                />
                Additional practical task
              </li>
              <li className="flex gap-3 items-center">
                <img
                  className="w-4 h-4"
                  src={pkg.green}
                  alt="Green Rectangle"
                />
                Monthly conferences
              </li>
              <li className="flex gap-3 items-center">
                <img
                  className="w-4 h-4"
                  src={pkg.green}
                  alt="Green Rectangle"
                />
                Personal advice from teachers
              </li>
            </ul>
            <div className="w-full mx-auto mt-8 flex items-center justify-center">
              <button className="btnPrimary">Get Started</button>
            </div>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default Pricing;
